import Swiper from "swiper";

import { Component } from "../extends";

export default class SwiperCarousel extends Component {
	mounted() {
		this.swiper = new Swiper(this.el, {
			spaceBetween: 30,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}
}
