import SwiperCore, { Navigation } from "swiper/core";

import components from "./components";
import { init as initComponent } from "./utils/dom";

const init = () => {
	// Configure Swiper
	SwiperCore.use([Navigation]);

	// Initialise & register all JavaScript components
	initComponent(".js-body", components.Body);
	initComponent(".js-site-hamburger", components.SiteHamburger);
	initComponent(".js-site-mobile-nav", components.SiteMobileNav);
	initComponent(".js-animated-steps", components.AnimatedSteps);
	initComponent(".js-animated-team", components.AnimatedTeam);
	initComponent(".js-overlap", components.Overlap);
	initComponent(".js-swiper-carousel", components.SwiperCarousel);
	initComponent(".js-site-header", components.SiteHeader);
	initComponent(".js-cases", components.Cases);
	initComponent(".js-events", components.Events);
	initComponent(".js-collapse", components.Collapse);
	initComponent(".js-modal-trigger", components.ModalTrigger);
};

/**
 * Check if the document is ready to initialise the JavaScript components.
 * Interactive indicates: execute when document has finished loading, but
 * images, stylesheets and frames are still loading.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */
document.onreadystatechange = () => {
	if (document.readyState === "interactive") {
		init();
	}
};

document.addEventListener("sprig-refresh", () => {
	init();
});
