import Headroom from "headroom.js";
// Make use of a ponyfill for ResizeObserver because Safari doesn't support it.
import ResizeObserver from "resize-observer-polyfill";

import { Component } from "../extends";

export default class SiteHeader extends Component {
	events() {
		this.resizeObserver = new ResizeObserver(() => this.resize());
		this.resizeObserver.observe(this.el);
		this.$EE.on("SiteMobileNav:focus-last", () => this.focusLogo());
		this.$EE.on("SiteMobileNav:focus-first", () => this.focusHamburger());
	}

	mounted() {
		this.set();

		this.logo = this.el.querySelector(".c-site-header__site-logo");
		this.hamburger = this.el.querySelector(
			".c-site-header__site-hamburger"
		);

		this.headroom = new Headroom(this.el, {
			classes: {
				pinned: "is-pinned",
				unpinned: "is-pinned",
				top: "is-top",
				notTop: "is-not-top",
				bottom: "is-bottom",
				notBottom: "is-not-bottom",
				frozen: "is-frozen",
			},
			offset: 10,
		});
		this.headroom.init();
	}

	/**
	 * If the fixed modifier is not present on the class, set it, this will
	 * avoid a jump in the content when the JavaScript is triggered.
	 */
	set() {
		if (!this.el.classList.contains("c-site-header--fixed")) {
			this.el.classList.add("c-site-header--fixed");
		}
	}

	resize() {
		const { height } = this.el.getBoundingClientRect();

		this.$EE.emit("SiteHeader:resize", height);
	}

	onKeydown(e) {
		if (!this.$Store.isSiteMobileNavOpen) {
			return;
		}

		const isShift = e.shiftKey;
		const focusedEl = document.activeElement;

		if (this.logo === focusedEl && isShift) {
			this.$EE.emit("SiteHeader:focus-out-logo");
			e.preventDefault();
		}
	}

	focusLogo() {
		this.logo.focus();
	}

	focusHamburger() {
		this.hamburger.focus();
	}
}
