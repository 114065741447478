import gsap from "gsap";

import { Component } from "../extends";

export default class SiteMobileNav extends Component {
	events() {
		this.$EE.on("SiteHamburger:enable", () => this.open());
		this.$EE.on("SiteHamburger:disable", () => this.close());
		this.$EE.on("SiteHeader:resize", (offset) =>
			this.setTopHeaderOffset(offset)
		);
		this.$EE.on("SiteHeader:focus-out-logo", () => this.focusLast());
	}

	mounted() {
		this.items = [...this.el.querySelectorAll(".c-site-nav__item")];
		this.focusEls = [
			...this.el.querySelectorAll(
				".c-site-nav__link, .c-site-nav__button"
			),
		];
	}

	animations() {
		this.masterTl = gsap.to(this.items, {
			paused: true,
			y: 0,
			opacity: 1,
			ease: "expo.inOut",
			duration: 0.6,
			stagger: 0.05,
		});
	}

	open() {
		this.el.classList.add("is-open");
		this.masterTl.seek(0);
		this.masterTl.play();
	}

	close() {
		this.el.classList.remove("is-open");
		this.masterTl.reverse();
	}

	setTopHeaderOffset(offset) {
		this.el.style.paddingTop = `${offset}px`;
	}

	onKeydown(e) {
		if (!this.$Store.isSiteMobileNavOpen) {
			return;
		}

		const isTab = e.key === "Tab" || e.keyCode === 9;
		const isShift = e.shiftKey;

		if (!isTab) {
			return;
		}

		const focusedEl = document.activeElement;
		const firstEl = this.focusEls[0];
		const lastEl = this.focusEls[this.focusEls.length - 1];

		if (focusedEl === firstEl && isShift) {
			this.$EE.emit("SiteMobileNav:focus-first");
			e.preventDefault();
		}

		if (focusedEl === lastEl && !isShift) {
			this.$EE.emit("SiteMobileNav:focus-last");
			e.preventDefault();
		}
	}

	focusLast() {
		const lastEl = this.focusEls[this.focusEls.length - 1];

		lastEl.focus();
	}
}
