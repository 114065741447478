import { Component } from "../extends";

export default class LoadMore extends Component {
	mounted(entryPoint, { renderLoadMoreSuccessCb, loadSuccessCb } = {}) {
		this.entryPoint = entryPoint;
		this.renderLoadMoreSuccessCb = renderLoadMoreSuccessCb || (() => null);
		this.loadSuccessCb = loadSuccessCb || (() => null);
		this.currentPage = 1;
		this.isLoading = false;

		this.renderLoadMore();
	}

	events() {
		this.el.addEventListener("click", () => this.loadMore());
	}

	renderLoadMore() {
		fetch(
			`${DEFAULT_SITE_URL}/api/${this.entryPoint}?pg=${this.currentPage}`
		)
			.then((response) => response.json())
			.then((data) => this.renderLoadMoreSuccess(data));
	}

	renderLoadMoreSuccess(data) {
		const { total_pages: totalPages } = data.meta.pagination;

		if (this.currentPage < totalPages) {
			this.renderLoadMoreSuccessCb();
		}
	}

	loadMore() {
		if (this.isLoading) {
			return;
		}

		this.currentPage += 1;
		this.isLoading = true;

		this.disable();

		fetch(
			`${DEFAULT_SITE_URL}/api/${this.entryPoint}?pg=${this.currentPage}`
		)
			.then((response) => response.json())
			.then((data) => this.loadSuccess(data));
	}

	loadSuccess(data) {
		this.enable();
		this.isLoading = false;

		this.loadSuccessCb(data);
	}

	disable() {
		this.el.classList.add("is-disabled");
	}

	enable() {
		this.el.classList.remove("is-disabled");
	}
}
