import { Component } from "../extends";

export default class Collapse extends Component {
	mounted() {
		this.toggleClass = "is-open";
		this.isToggled = this.el.classList.contains(this.toggleClass);

		this.content = this.el.querySelector(".c-collapse__content");
		this.container = this.el.querySelector(".c-collapse__container");

		this.setHeight();

		if (this.isToggled) {
			this.enable();
		}
	}

	events() {
		this.resizeObserver = new ResizeObserver(() => this.onResize());

		this.resizeObserver.observe(this.el);

		this.el.addEventListener("click", () => this.toggle());
	}

	getHeight() {
		const { height } = this.container.getBoundingClientRect();

		return height;
	}

	toggle() {
		if (!this.isToggled) {
			this.enable();
		} else {
			this.disable();
		}
	}

	enable() {
		this.isToggled = true;

		// Open collapse
		this.el.classList.add(this.toggleClass);
		this.setHeight();

		// Update the aria attributes
		this.el.setAttribute("aria-label", "Close collapse");
		this.el.setAttribute("aria-expanded", "true");
	}

	disable() {
		this.isToggled = false;

		// Close collapse
		this.unsetHeight();
		this.el.classList.remove(this.toggleClass);

		// Update the aria attributes
		this.el.setAttribute("aria-label", "Open collapse");
		this.el.setAttribute("aria-expanded", "false");
	}

	setHeight() {
		const height = this.getHeight();

		// this.container.style.height = `${height}px`;
		this.el.style.setProperty(
			"--component-collapse-container-height",
			`${height}px`
		);
	}

	unsetHeight() {
		this.el.style.setProperty("--component-collapse-container-height", 0);
	}

	getIndex() {
		return this.el.dataset.accordionIndex;
	}

	onResize() {
		this.getHeight();
		this.setHeight();
	}
}
