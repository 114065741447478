// Make use of a ponyfill for ResizeObserver because Safari doesn't support it.
import ResizeObserver from "resize-observer-polyfill";

import { Component } from "../extends";

export default class Overlap extends Component {
	events() {
		this.resizeObserver = new ResizeObserver(() => this.resize());
		this.resizeObserver.observe(this.el);
	}

	mounted() {
		this.set();

		this.prevSibling = this.el.previousElementSibling;
		this.child = this.el.firstElementChild;

		this.setNextSibling();
		this.setRatio();
		this.setChildTranslate();
	}

	// If the u-overlap is not present on the class, set it
	set() {
		if (!this.el.classList.contains("u-overlap")) {
			this.el.classList.add("u-overlap");
		}
	}

	/**
	 * If the attribute data-overlap-next-parent is placed on the element, then
	 * instead of the next sibling, the next sibling of the element's parent is
	 * selected.
	 */
	setNextSibling() {
		const getParent = !!this.el.hasAttribute("data-overlap-next-parent");

		this.nextSibling = getParent
			? this.el.parentNode.nextElementSibling
			: this.el.nextElementSibling;
	}

	/**
	 * Attribute between 0 and 100 which indicates how much percent of the
	 * element will be overlapping the next sibling.
	 */
	setRatio() {
		this.ratio =
			parseFloat(this.el.getAttribute("data-overlap-ratio")) || 50;
	}

	// Based on the ratio, the translation is set on the child element
	setChildTranslate() {
		const translate = 100 - this.ratio;

		this.child.style.transform = `translateX(-50%) translateY(-${translate}%)`;
	}

	resize() {
		if (!this.child) {
			return;
		}

		// Get the height of the child positioned absolute
		const { height } = this.child.getBoundingClientRect();
		const offsetAbove = height * ((100 - this.ratio) * 0.01);
		const offsetBelow = height * (this.ratio * 0.01);

		if (this.prevSibling) {
			this.resizePrevSibling(offsetAbove);
		}

		if (this.nextSibling) {
			this.resizeNextSibling(offsetBelow);
		}
	}

	/**
	 * Get the padding from the previous sibling to add the offset height of the
	 * child element and set its new padding.
	 */
	resizePrevSibling(offset) {
		this.prevSibling.style.removeProperty("padding-bottom");

		const padding = parseFloat(
			window
				.getComputedStyle(this.prevSibling)
				.getPropertyValue("padding-bottom")
		);
		const newPadding = padding + offset;

		this.prevSibling.style.paddingBottom = `${newPadding}px`;
	}

	/**
	 * Get the padding from the next sibling to add the offset height of the
	 * child element and set its new padding.
	 */
	resizeNextSibling(offset) {
		this.nextSibling.style.removeProperty("padding-top");

		const padding = parseFloat(
			window
				.getComputedStyle(this.nextSibling)
				.getPropertyValue("padding-top")
		);
		const newPadding = padding + offset;

		this.nextSibling.style.paddingTop = `${newPadding}px`;
	}
}
