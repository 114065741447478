import { Component } from "../extends";

export default class SiteHamburger extends Component {
	events() {
		this.el.addEventListener("click", () => this.toggle());
	}

	toggle() {
		if (!this.$Store.isSiteMobileNavOpen) {
			this.enable();
		} else {
			this.disable();
		}
	}

	enable() {
		if (this.$Store.isSiteMobileNavOpen) {
			return;
		}

		const { scrollY } = window;

		this.$Store.isSiteMobileNavOpen = true;
		this.$Store.scrollY = scrollY;

		this.el.classList.add("is-cross");
		this.el.setAttribute("aria-label", "Close menu");
		this.el.setAttribute("aria-expanded", "true");

		this.$EE.emit("SiteHamburger:enable");
	}

	disable() {
		if (!this.$Store.isSiteMobileNavOpen) {
			return;
		}

		this.$Store.isSiteMobileNavOpen = false;

		this.el.classList.remove("is-cross");
		this.el.setAttribute("aria-label", "Open menu");
		this.el.setAttribute("aria-expanded", "false");

		this.$EE.emit("SiteHamburger:disable");

		window.scrollTo(0, this.$Store.scrollY);
	}
}
