let instance = null;

class Keyboard {
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.callbacks = [];
		this.events();

		return instance;
	}

	events() {
		window.addEventListener("keydown", (e) => this.onKeydown(e));
	}

	add(callback) {
		this.callbacks.push(callback);
	}

	onKeydown(e) {
		for (let i = 0; i < this.callbacks.length; i += 1) {
			const callback = this.callbacks[i];

			callback(e);
		}
	}
}

export default new Keyboard();
