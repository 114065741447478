import gsap from "gsap";
import VanillaTilt from "vanilla-tilt";

import { Component } from "../extends";

// TODO: mobile animation
export default class AnimatedTeam extends Component {
	mounted() {
		this.amount = parseInt(
			this.el.getAttribute("data-animated-team-length")
		);
		this.pauseDuration = 1000;
		this.items = this.el.querySelector(".c-animated-team__items");
		this.images = [...this.el.querySelectorAll(".c-animated-team__image")];
		this.texts = [...this.el.querySelectorAll(".c-animated-team__text")];

		VanillaTilt.init(this.items, {
			reverse: true,
			"full-page-listening": true,
		});
	}

	animations() {
		this.masterTl = gsap.timeline({
			delay: this.pauseDuration / 1000,
			onComplete: () => this.onMasterComplete(),
		});

		for (let i = 0; i < this.amount; i += 1) {
			this.addSubAnim(i);
		}
	}

	addSubAnim(index) {
		const nextIndex = index + 1;
		const image = this.images[index];
		const text = this.texts[index];
		const nextImage =
			index >= this.amount - 1 ? this.images[0] : this.images[nextIndex];
		const nextText =
			index >= this.amount - 1 ? this.texts[0] : this.texts[nextIndex];

		const tl = gsap.timeline({
			onComplete: () => this.onMasterPause(),
		});

		if (image) {
			// prettier-ignore
			tl.to(image, {
				x: "-100%",
				duration: 1.5,
				ease: "expo.inOut",
			}, 0);

			// prettier-ignore
			tl.set(image, {
				x: "100%",
			}, 1.5);
		}

		if (text) {
			// prettier-ignore
			tl.to(text, {
				y: "-100%",
				opacity: 0,
				duration: 1,
				ease: "back.inOut(2.6)",
			}, 0.5);

			// prettier-ignore
			tl.set(text, {
				y: "100%",
				opacity: 0,
			}, 1.5);
		}

		if (nextImage) {
			// prettier-ignore
			tl.to(nextImage, {
				x: 0,
				duration: 1.5,
				ease: "expo.inOut",
			}, 0);
		}

		if (nextText) {
			// prettier-ignore
			tl.to(nextText, {
				y: 0,
				opacity: 1,
				duration: 1,
				ease: "back.inOut(2.6)",
			}, 0.5);
		}

		this.masterTl.add(tl);
	}

	onMasterPause() {
		this.masterTl.pause();

		this.pauseTimeout = setTimeout(
			() => this.onMasterPaused(),
			this.pauseDuration
		);
	}

	onMasterPaused() {
		if (this.pauseTimeout) {
			clearTimeout(this.pauseTimeout);
			this.pauseTimeout = null;
		}

		this.masterTl.play();
	}

	onMasterComplete() {
		this.masterTl.seek(0);
	}
}
