export const init = (selector, Module = null, params = {}) => {
	const els = document.querySelectorAll(selector) || [];
	const instances = Module
		? [...els].map((el) => new Module(el, params))
		: [...els];

	if (instances.length === 1) {
		return instances[0] || null;
	}

	return instances || [];
};
