let instance = null;

class Store {
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.state();

		return instance;
	}

	state() {
		this.isSiteMobileNavOpen = false;
		this.scrollY = 0;
	}
}

export default new Store();
