import { Component } from "../extends";

export default class Body extends Component {
	events() {
		this.$EE.on("SiteHamburger:enable", () => this.disableScroll());
		this.$EE.on("SiteHamburger:disable", () => this.enableScroll());
		this.$EE.on("SiteHeader:resize", (offset) =>
			this.setTopHeaderOffset(offset)
		);
	}

	disableScroll() {
		this.el.classList.add("has-scroll-disabled");
	}

	enableScroll() {
		this.el.classList.remove("has-scroll-disabled");
	}

	setTopHeaderOffset(offset) {
		this.el.style.paddingTop = `${offset}px`;
	}
}
