import { Component } from "../extends";
import { LoadMore } from "../modules";

export default class Cases extends Component {
	mounted() {
		this.footer = this.el.querySelector(".c-cases__footer");
		this.list = this.el.querySelector(".c-cases__list");
		// Clone selected template to avoid adjusting the original node
		this.template = this.el.querySelector(".c-cases__item").cloneNode(true);

		this.loadMore = new LoadMore(
			this.el.querySelector(".c-cases__button"),
			"cases",
			{
				renderLoadMoreSuccessCb: () => this.showFooter(),
				loadSuccessCb: (data) => this.loadSuccess(data),
			}
		);
	}

	loadSuccess(data) {
		const { meta, data: cases } = data;
		const { total_pages: totalPages, current_page: currentPage } =
			meta.pagination;

		this.renderCases(cases);

		if (currentPage === totalPages) {
			this.hideFooter();
		}
	}

	hideFooter() {
		this.footer.classList.add("u-hide");
	}

	showFooter() {
		this.footer.classList.remove("u-hide");
	}

	renderCases(cases) {
		cases.forEach(({ title, url, thumbnail, summary, linkText }) => {
			const template = this.template.cloneNode(true);

			const titleEl = template.querySelector(".c-article-preview__title");
			const innerEl = template.querySelector(".c-article-preview__inner");
			const linkTextEl = template.querySelector(".c-link__text");
			const textEl = template.querySelector(".c-article-preview__text");
			const imageEl = template.querySelector(".c-article-preview__image");
			const contentEl = template.querySelector(
				".c-article-preview__content"
			);

			if (title) {
				titleEl.innerText = title;
			} else {
				contentEl.removeChild(titleEl);
			}

			if (summary) {
				textEl.innerHTML = summary || "";
			} else {
				contentEl.removeChild(textEl);
			}

			if (thumbnail) {
				imageEl.setAttribute(
					"style",
					`background-image: url(${thumbnail})`
				);
			} else {
				innerEl.removeChild(imageEl);
			}

			linkTextEl.innerText = linkText;
			innerEl.setAttribute("href", url);

			this.list.appendChild(template);
		});
	}
}
