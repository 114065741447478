import { Component } from "../extends";

// TODO: mobile refinement: turn off everything
export default class AnimatedSteps extends Component {
	events() {
		this.intersectionObserver = new IntersectionObserver(
			(entry) => this.intersect(entry),
			{ rootMargin: "-50% 0% -50% 0%", threshold: [0, 1] }
		);

		this.steps.forEach((step) => this.intersectionObserver.observe(step));
	}

	mounted() {
		this.steps = [...this.el.querySelectorAll(".c-animated-steps__step")];
		this.desktopImagesWrapper = this.el.querySelector(
			".c-animated-steps__desktop-images"
		);
		this.desktopImages = [
			...this.el.querySelectorAll(".c-animated-steps__desktop-image"),
		];

		this.active = 0;
	}

	intersect(entries) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				this.stepIntersected(entry.target);
			}
		});
	}

	stepIntersected(step) {
		const index = step.getAttribute("data-index");
		const prevStep = this.steps[this.active];
		const prevIndex = prevStep.getAttribute("data-index");

		this.activateStep(step);
		this.hideActiveImage();
		this.active = index;
		if (index !== prevIndex) {
			this.deactivateStep(prevStep);
		}
		this.showActiveImage();
	}

	hideActiveImage() {
		const image = this.desktopImages[this.active];

		image.classList.add("is-hidden");
	}

	showActiveImage() {
		const image = this.desktopImages[this.active];

		image.classList.remove("is-hidden");
	}

	activateStep(step) {
		const wrapper = step.parentNode;

		wrapper.classList.add("is-active");
	}

	deactivateStep(step) {
		const wrapper = step.parentNode;

		wrapper.classList.remove("is-active");
	}
}
