import { Component } from "../extends";

export default class ModalTrigger extends Component {
	mounted() {
		this.open = false;
		this.id = this.el.getAttribute("data-id");
		this.modal = document.querySelector(`.js-modal[data-id="${this.id}"]`);
		this.modalCloseButton = this.modal.querySelector(".js-modal-close");
	}

	events() {
		this.el.addEventListener("click", () => this.toggle());
		this.modal.addEventListener("click", (e) => this.backdropHandler(e));
		this.modalCloseButton.addEventListener("click", () => this.disable());
	}

	toggle() {
		if (!this.open) {
			this.enable();
		} else {
			this.disable();
		}
	}

	backdropHandler(e) {
		// Check if the click is outside the dialog content
		if (!e.target.closest(".c-modal__content")) {
			this.disable();
		}
	}

	enable() {
		this.open = true;

		this.modal.showModal();

		this.el.setAttribute("aria-label", "Close menu");
		this.el.setAttribute("aria-expanded", "true");

		this.modalCloseButton.focus();
	}

	disable() {
		this.open = false;

		this.modal.close();

		this.el.setAttribute("aria-label", "Open menu");
		this.el.setAttribute("aria-expanded", "false");
	}
}
