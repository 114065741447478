import AnimatedSteps from "./AnimatedSteps";
import AnimatedTeam from "./AnimatedTeam";
import Body from "./Body";
import Cases from "./Cases";
import Collapse from "./Collapse";
import Events from "./Events";
import Overlap from "./Overlap";
import SiteHamburger from "./SiteHamburger";
import SiteHeader from "./SiteHeader";
import SiteMobileNav from "./SiteMobileNav";
import SwiperCarousel from "./SwiperCarousel";

export default {
	AnimatedSteps,
	AnimatedTeam,
	Body,
	Cases,
	Events,
	SiteHamburger,
	SiteMobileNav,
	Overlap,
	SwiperCarousel,
	SiteHeader,
	Collapse,
};
